:root {
    --main-color: #37474f;
    --white: #fff;
}

:focus {
    outline: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
    min-width: 375px;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: "Roboto", sans-serif;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
}

.MuiSelect-select.MuiSelect-select:focus {
    background: transparent;
}
